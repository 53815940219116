@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fortawesome/fontawesome-free/css/all.min.css";

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
    background-color: #EDF9FE;
  }
}
